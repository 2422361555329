<template>
  <div>
    <div class="col float-right">
      <q-btn-group outline>
        <!-- 탭으로 삭제 변경 -->
        <!-- <c-btn v-show="tabParam.accidentStatusCd === 'ISPC000001' && !disabled" label="LBLREMOVE" :editable="editable" icon="delete_forever" @btnClicked="remove" /> -->
        <!-- 완료 -->
        <c-btn 
          v-show="tabParam.accidentStatusCd === 'ISPC000001' && !disabled" 
          :isSubmit="isComplete"
          :url="completeUrl"
          :param="tabParam"
          :mappingType="completeType"
          label="LBLCOMPLETE" 
          icon="check"
          @beforeAction="completeAccident"
          @btnCallback="completeCallback" />
      </q-btn-group>
    </div>
    <c-tab
      type="vertical"
      :tabItems="tabItems"
      :height.sync="tabHeight"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :popupParam="param"
          :tabParam="tabParam"
          @transInfo="transInfo"
          @setReportTab="setReportTab"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
import { uid } from 'quasar'
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'process-accident-register',
  props: {
    param: {
      type: Object,
      default: () => ({
        iimAccidentId: '',
        stepCd: '',
        count: 0,
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      tabParam: {
        iimAccidentId: '',
        accidentTypeCd: '',
        plantCd: '',
        accidentNo: '',
        accidentName: '',
        accidentStatusCd: '',
        reportUserId: '',
        reportDate: '',
        accidentKindCd: '',
        accidentGradeCd: null,
        occurrenceDate: '',
        occurrenceDeptCd: '',
        occurrenceLocation: '',
        weather: '',
        emergencyMeasures: '',
        overviewAccidentSituation: '',
        occurrenceModeLargeCd: null,
        occurrenceModeSmallCd: null,
        humanDamage: '',
        directCause: '',
        indirectCause: '',
        openning: '',
        injurious: '',
        materialDamageHistory: '',
        humanDamageHistory: '',
        toxicFlag: 'N',
        accidentVictimModelList: [], // 관련 사고자
        deleteAccidentVictimModelList: [], // 사고자 삭제 모델
        accidentMaterialModelList: [], // 관련설비 및 물질
        emergencyList: [], // 비상조치계획서
        damageClassCd: null,
        woundCd: null,
      },
      completeUrl: 'transactionConfig.sop.iim.accident.process.insert.url',
      completeType: 'POST',
      isComplete: false,
      insertUrl: '',
      deleteUrl: '',
      updateUrl: '',
      editable: true,
      splitter: 5,
      tab: 'processAccidentRegisterInfo',
      // tabItems: [],
    };
  },
  computed: {
    tabHeight() {
      let numHeight = this.height ? Number(this.$_.replace(this.height, 'px', '')) : 600;
      numHeight = numHeight - 40
      return numHeight + 'px';
    },
    tabItems() {
      return [ 
        // 사고정보
        { key: uid(), name: 'processAccidentRegisterInfo', icon: 'edit', label: 'LBL0002314', component: () => import(`${'./processAccidentRegisterInfo.vue'}`) },
        // 피해정도
        { key: uid(), name: 'processDegreeDamage', icon: 'how_to_reg', label: 'LBL0002315', component: () => import(`${'./processDegreeDamage.vue'}`), },
        // 사고관련 사진
        { key: uid(), name: 'processRelationPicture', icon: 'camera_enhance', label: 'LBL0002316', component: () => import(`${'./processRelationPicture.vue'}`),  },
        // 사고설비 및 물질
        { key: uid(), name: 'processAccidentEquipment', icon: 'checklist', label: 'LBL0002317', component: () => import(`${'./processAccidentEquipment.vue'}`), },
        // 관련 비상조치계획서
        { key: uid(), name: 'processAcidentEmergencyPlan', icon: 'manage_search', label: 'LBL0002318', component: () => import(`${'./processAcidentEmergencyPlan.vue'}`),  },
        // 위험성 평가
        { key: uid(), name: 'processAccidentRisk', icon: 'manage_search', label: 'LBL0002319', component: () => import(`${'./processAccidentRisk.vue'}`),  },
      ]
    },
    disabled() {
      return !this.tabParam.accidentStatusCd || this.tabParam.accidentStatusCd === 'ISPC000005'
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.insertUrl = transactionConfig.sop.iim.accident.process.insert.url;
      this.updateUrl = transactionConfig.sop.iim.accident.process.update.url;
      this.deleteUrl = transactionConfig.sop.iim.accident.process.delete.url;

      if (!this.param.iimAccidentId) {
        this.tabItems[1].disabled = true;
        this.tabItems[2].disabled = true;
        this.tabItems[3].disabled = true;
        this.tabItems[4].disabled = true;
      }
      // if (this.param.iimAccidentId) {
      //   this.tabItems = [
      //     { key: uid(), name: 'processAccidentRegisterInfo', icon: 'edit', label: '사고정보', component: () => import(`${'./processAccidentRegisterInfo.vue'}`) },
      //     { key: uid(), name: 'processDegreeDamage', icon: 'how_to_reg', label: '피해정도', component: () => import(`${'./processDegreeDamage.vue'}`), },
      //     { key: uid(), name: 'processRelationPicture', icon: 'camera_enhance', label: '사고관련 사진', component: () => import(`${'./processRelationPicture.vue'}`), },
      //     { key: uid(), name: 'processAccidentEquipment', icon: 'checklist', label: '사고설비 및 물질', component: () => import(`${'./processAccidentEquipment.vue'}`), },
      //     { key: uid(), name: 'processAcidentEmergencyPlan', icon: 'manage_search', label: '관련 비상조치계획서', component: () => import(`${'./processAcidentEmergencyPlan.vue'}`), },
      //   ]
      // } else {
      //   this.tabItems = [
      //     { key: uid(), name: 'processAccidentRegisterInfo', icon: 'edit', label: '사고정보', component: () => import(`${'./processAccidentRegisterInfo.vue'}`) },
      //     { key: uid(), name: 'processDegreeDamage', icon: 'how_to_reg', label: '피해정도', component: () => import(`${'./processDegreeDamage.vue'}`), disabled: true},
      //     { key: uid(), name: 'processRelationPicture', icon: 'camera_enhance', label: '사고관련 사진', component: () => import(`${'./processRelationPicture.vue'}`), disabled: true },
      //     { key: uid(), name: 'processAccidentEquipment', icon: 'checklist', label: '사고설비 및 물질', component: () => import(`${'./processAccidentEquipment.vue'}`), disabled: true },
      //     { key: uid(), name: 'processAcidentEmergencyPlan', icon: 'manage_search', label: '관련 비상조치계획서', component: () => import(`${'./processAcidentEmergencyPlan.vue'}`), disabled: true },
      //   ]
      // }
    },
    completeAccident() {
      this.completeUrl = transactionConfig.sop.iim.accident.process.complete.url;
      this.completeType = 'PUT';
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSG0000701', // 사고등록 하시겠습니까?(저장과 함께 등록됩니다.)
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.tabParam.accidentStatusCd = 'ISPC000002'
          this.$_.forEach(this.tabParam.accidentVictimModelList, _item => {
            if (_item.expectTreatmentDate) _item.expectTreatmentDate = _item.expectTreatmentDate.join();
          })
          this.tabParam.regUserId = this.$store.getters.user.userId
          this.tabParam.chgUserId = this.$store.getters.user.userId
          
          this.isComplete = !this.isComplete;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    completeCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$_.forEach(this.tabParam.accidentVictimModelList, _item => {
        if (_item.expectTreatmentDate) {
          _item.expectTreatmentDate = _item.expectTreatmentDate.split(',')
        }
      })
      if (this.tabParam.accidentStatusCd) {
        this.$emit('emitStep', {
          name: 'currentStep',
          param: this.tabParam.accidentStatusCd 
        })
        this.$emit('emitStep', {
          name: 'setReportTab',
          param: { stepCd: this.tabParam.accidentStatusCd  },
        })
      }
    },
    /* eslint-disable no-unused-vars */ 
    remove() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.tabParam.iimAccidentId);
          this.$http.type = 'DELETE';
          this.$http.request((_result) => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            
            this.$emit('emitStep', {
              name: 'closePopup',
              param: {},
            })
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    transInfo(data) {
      this.tabParam = data;
      if (data.iimAccidentId) {
        // this.tabItems = [
        //   { key: uid(), name: 'processAccidentRegisterInfo', icon: 'edit', label: '사고정보', component: () => import(`${'./processAccidentRegisterInfo.vue'}`) },
        //   { key: uid(), name: 'processDegreeDamage', icon: 'how_to_reg', label: '피해정도', component: () => import(`${'./processDegreeDamage.vue'}`), },
        //   { key: uid(), name: 'processRelationPicture', icon: 'camera_enhance', label: '사고관련 사진', component: () => import(`${'./processRelationPicture.vue'}`), },
        //   { key: uid(), name: 'processAccidentEquipment', icon: 'checklist', label: '사고설비 및 물질', component: () => import(`${'./processAccidentEquipment.vue'}`),  },
        //   { key: uid(), name: 'processAcidentEmergencyPlan', icon: 'manage_search', label: '관련 비상조치계획서', component: () => import(`${'./processAcidentEmergencyPlan.vue'}`),  },
        // ]
      }
    },
    setReportTab(groupId) {
      this.$emit('emitStep', {
        name: 'setReportTab',
        param: { groupId: groupId, stepCd: 'ISPC000001' },
      })
    }
  }
};
</script>
